<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small" @submit.native.prevent>
      <el-form-item label="标题" prop="title">
        <el-input v-model="info.title" placeholder="请输入标题"></el-input>
      </el-form-item>
<!--      <el-form-item label="图片" prop="img">-->
<!--        <SelectImgs :selectNum="1" :selectData="[info.img]" :selectFunc="picChoose" :idx="1"></SelectImgs>-->
<!--      </el-form-item>-->
      <el-form-item label="简介">
        <el-input type="textarea" rows="4" v-model="info.intro" placeholder="请输入简介"></el-input>
      </el-form-item>
      <el-form-item label="详情" prop="content">
        <wang-edit :txt="info.content" :callback="setText"></wang-edit>
      </el-form-item>
      <el-form-item label="视频">
        <div style="display: flex; align-items: center">
          <video-upload :selectFunc="fileChoose"></video-upload>
          <span v-if="info.video_url">已上传</span>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button type="button" @click="reBack()">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

// import SelectImgs from '../../components/SelectImgs'
import WangEdit from "../../components/WangEdit";
import VideoUpload from "@/components/VideoUpload";
export default {
  data() {
    return {
      info: {
        id: 0,
        title: '',
        img: '',
        intro: '',
        content: '',
      },
      source_domain: this.config.SOURCE_DOMAIN,
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
      }
    };
  },
  created() {
    this.getInfo()
  },
  components: {
    // SelectImgs,
    WangEdit,
    VideoUpload
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    fileChoose(item) {
      this.info.video_url = item.url
    },
    picChoose(item) {
      this.info.img = item.pic
    },
    setText(txt) {
      this.info.content = txt
    },
    getInfo() {
      var that = this
      this.$api.about.aboutIntroInfo({}, function (res) {
        if(res.errcode == 0) {
          that.info = res.data
        }
      })
    },
    submitForm(formName) {
      var that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var param = that.info
          this.$api.about.aboutIntroEdit(param,function (res) {
            if(res.errcode == 0) {
              that.success(res.errmsg)
            } else {
              that.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    },
  }
};
</script>
<style scoped>
  .box {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    min-height: 500px;
  }
</style>
